<script setup lang="ts">
import { UserGroup } from '@tn/shared';
import tokens from '@/assets/paywall_tokens.svg';
import discounts from '@/assets/paywall_discounts.svg';
import premium from '@/assets/paywall_premium.svg';
import EVENTS from '~/constants/events';

const props = defineProps({
  section: {
    type: String,
    required: true,
  },
  userGroup: {
    type: String,
    required: false,
    default: UserGroup.ReoccurringTokens,
  },
});

const { $trackEvent } = useNuxtApp();
const route = useRoute();

const items = [
  {
    id: 'discounts',
    heading: 'Exclusive Discounts',
    description: 'On Taste Network brands',
    image: discounts,
  },
  {
    id: 'tokens',
    heading: 'Unlock Free Tokens',
    description:
      props.userGroup === UserGroup.ReoccurringTokens
        ? 'Get new products each month'
        : 'Complete activities to earn free sample tokens',
    image: tokens,
  },
  {
    id: 'premium',
    heading: 'Carb Manager Premium',
    description: 'Recipes, advanced tracking, & more',
    image: premium,
  },
];

const modal = useModal();

const onClickGetPremium = () => {
  $trackEvent(EVENTS.GET_PREMIUM_TODAY_CLICKED, {
    page: route.meta.eventPageName,
    section: props.section,
    user_group: props.userGroup,
  });
};
</script>

<template>
  <UModal
    :ui="{
      base: '!max-w-[960px]',
      width: 'sm:w-[98%]',
      padding: 'p-0',
      margin: 'sm:mx-0',
      rounded: 'rounded-[20px]',
    }"
  >
    <div
      class="overflow-hidden rounded-tl-[20px] rounded-tr-[20px] border-[2px] border-b-[4px] border-black sm:rounded-[20px]"
    >
      <div class="bg-beige-400 text-center">
        <div class="absolute left-4 top-4">
          <button @click="modal.close()">
            <UIcon name="i-heroicons-x-mark" class="h-6 w-6" />
          </button>
        </div>
        <div class="mx-auto max-w-[580px] space-y-2 p-4 pt-8">
          <TnHeading :ui="{ wrapper: '!text-4xl uppercase text-yellow-500' }"
            >Go Premium</TnHeading
          >
          <p v-if="props.userGroup === UserGroup.ReoccurringTokens">
            Oops! It looks like you don't have an active Carb Manager Premium
            subscription. To order products on Taste Network, activate Premium
            now and get 25% off.
          </p>
          <p v-else>
            Premium members get top-tier rewards, free sample tokens, and
            exclusive deals—upgrade today and get 25% off!
          </p>
        </div>

        <UCarousel
          v-slot="{ item }"
          :items="items"
          :ui="{
            item: `relative overflow-hidden basis-[90%] md:basis-1/3 rounded-[20px] border-[2px] border-b-[4px] border-black bg-yellow-800 h-[240px] sm:h-auto sm:aspect-[11/8] sm:max-w-[340px] mx-[5px] md:mx-0 md:[&:nth-child(odd)]:scale-90 md:[&:nth-child(even)]:-mx-[20px] md:[&:nth-child(even)]:z-30`,
            wrapper: 'w-full mt-4 relative md:left-[20px]',
            indicators: {
              wrapper:
                'md:hidden relative bottom-0 mt-4 flex items-center justify-center gap-3 inset-x-0',
              base: 'rounded-full h-3 w-3',
              active: 'bg-black',
              inactive: 'bg-[#D4CBC3] mix-blend-normal',
            },
          }"
          indicators
          class="overflow-hidden rounded-lg"
        >
          <div class="flex w-full flex-col px-4 text-center">
            <div>
              <h3
                class="font-poppins mb-2 mt-4 text-lg font-semibold leading-tight"
              >
                {{ item.heading }}
              </h3>
              <p class="text-sm">{{ item.description }}</p>
            </div>
            <div class="mt-auto">
              <img
                :src="item.image"
                :draggable="false"
                class="mx-auto h-auto max-w-[230px] sm:max-w-[70%]"
                :class="{
                  'mb-4': item.id === 'tokens',
                  'mb-2': item.id === 'premium',
                  'relative top-[35px]': item.id === 'discounts',
                }"
              />
            </div>
          </div>
        </UCarousel>

        <div class="relative mt-10 border-t border-black bg-white p-6">
          <div class="absolute -top-[1px] left-0 right-0">
            <div
              class="shadow-cartoon inline-flex items-center space-x-2 rounded-bl-lg rounded-br-lg border border-black bg-green-500 px-4 py-0.5 text-center text-sm font-semibold uppercase text-white"
            >
              <span class="text-white">
                <img
                  src="@/assets/premium-trophy.svg"
                  alt="Carb Manager Premium"
                  class="h-4 w-4"
                />
              </span>
              <span> 25% Off </span>
            </div>
          </div>
          <div class="mx-auto pt-4 sm:max-w-[330px]">
            <UButton
              color="primary"
              variant="solid"
              block
              size="xl"
              to="https://my.carbmanager.com/daily-log?premium-overlay=49282&force=true"
              target="_blank"
              @click="onClickGetPremium"
            >
              Get Premium
            </UButton>
          </div>
        </div>
      </div>
    </div>
  </UModal>
</template>
